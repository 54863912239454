import { Component } from '@angular/core';
import { DecimalPipe } from "@angular/common";
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { catchError } from "rxjs/operators";
import { Subscription, of } from 'rxjs';
import { SharedModule } from "../shared/shared.module";
import { IDropDownItem } from "ev-dropdown-reactive";
import { EnergyLabelService } from "./services/energy-label.service";
import { ToastService } from "../shared/services/toast.service";
import { ReferenceTypesService } from "../shared/services/reference-types.service";
import { EnergyLabel } from './models/energy-label.model';

@Component({
  selector: 'app-energy-labels',
  standalone: true,
  imports: [
    SharedModule
  ],
  providers: [ EnergyLabelService ],
  templateUrl: './energy-labels.component.html',
  styleUrls: ['./energy-labels.component.scss', '../../assets/styles/form.scss']
})
export class EnergyLabelsComponent {
  numberPipe: DecimalPipe = new DecimalPipe('nb-NO');
  loading: boolean = false;
  inCreateMode: boolean = true;
  inReadOnlyMode: boolean = false;
  loadedEnergyLabel: EnergyLabel;
  certificateMethodItems: IDropDownItem[] = [];
  buildingMaterialItems: IDropDownItem[] = [];
  energyColorItems: IDropDownItem[] = [];
  buildingCategoryItems: IDropDownItem[] = [];

  energyPerformanceCertificateForm = new UntypedFormGroup({
    energyLabelID: new UntypedFormControl(null, [Validators.required]),
    energyLabelInfoID: new UntypedFormControl(null),
    issueDate: new UntypedFormControl(null, [Validators.required]),
    energyLabel: new UntypedFormControl(null, [Validators.required]),
    energyLabelingHeatingColorID: new UntypedFormControl(null, [Validators.required]),
    energyLabelCertificateMethodID: new UntypedFormControl(null, [Validators.required]),
    buildYear: new UntypedFormControl(null, [Validators.pattern("[0-9]*")]),
    energyBuildingCategoryID: new UntypedFormControl(null, [Validators.pattern("[0-9]*")]),
    buildingMaterialID: new UntypedFormControl(null, [Validators.pattern("[0-9]*")]),
    streetAddress: new UntypedFormControl(null),
    postCode: new UntypedFormControl(null),
    buildingNumber: new UntypedFormControl(null, [Validators.pattern("[0-9]*")]),
    functionalUnitNumber: new UntypedFormControl(null),
    organizationNumber: new UntypedFormControl(null, [Validators.pattern("[0-9]*")]),
    shareNumber: new UntypedFormControl(null, [Validators.pattern("[0-9]*")])
  });

  energyLabelItems: IDropDownItem[] = [
    {
        value: 'A',
        key: 'A',
    },
    {
        value: 'B',
        key: 'B',
    },
    {
        value: 'C',
        key: 'C',
    },
    {
        value: 'D',
        key: 'D',
    },
    {
        value: 'E',
        key: 'E',
    },
    {
        value: 'F',
        key: 'F',
    },
    {
        value: 'G',
        key: 'G',
    },
  ];

  private routeSub: Subscription;

  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private toastService: ToastService, 
    private energyLabelService: EnergyLabelService,
    private referenceTypes: ReferenceTypesService,
  ) {}

  ngOnInit() {
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.referenceTypes.getEnergyLabelCertificateMethods().subscribe((methods) => {
        this.certificateMethodItems = this.mapDropdownKeyValues(methods);
      });
      this.referenceTypes.getEnergyLabelingHeatingColors().subscribe((energyColors) => {
        this.energyColorItems = this.mapDropdownKeyValues(energyColors);
      });
      this.referenceTypes.getBuildingMaterials().subscribe((buildingMaterials) => {
        this.buildingMaterialItems = this.mapDropdownKeyValues(buildingMaterials);
      });
      this.referenceTypes.getEnergyBuildingCategories().subscribe((buildingCategories) => {
        this.buildingCategoryItems = this.mapDropdownKeyValues(buildingCategories);
      });

      const id = params['energyLabelInfoID'];
      if (id) {
        this.loadEnergyLabel(id);
      }
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  createNewMode(): void {
    this.energyPerformanceCertificateForm.enable();
    this.energyPerformanceCertificateForm.reset();
    this.inCreateMode = true;
    this.inReadOnlyMode = false;
  }

  private loadEnergyLabel(id: any) {
    this.loading = true;
    this.inCreateMode = false;
    this.energyPerformanceCertificateForm.controls.energyLabelInfoID.setValue(id);
    
    this.energyLabelService.getEnergyLabel(this.energyPerformanceCertificateForm.controls.energyLabelInfoID.value).pipe(
      catchError(error => {
        console.error(error);
        this.toastService.setMessage({ text: 'Det oppstod en feil ved henting av energiattest: ' + error?.error?.Message, type: 'error' });
        this.loading = false;
        return of({});
      })
    ).subscribe(x => {
      this.loadedEnergyLabel = x as EnergyLabel;
      this.energyPerformanceCertificateForm.reset(x);
      this.energyPerformanceCertificateForm.disable();
      this.inReadOnlyMode = true;
      this.loading = false;
    });
  }

  save(): void {
    this.loading = true;
    
    if (this.inCreateMode) {
      this.energyPerformanceCertificateForm.controls.energyLabelInfoID.disable();

      this.energyLabelService.createEnergyLabel(this.energyPerformanceCertificateForm.value).pipe(
        catchError(error => {
          console.error(error);
          this.toastService.setMessage({ text: 'Det oppstod en feil ved lagring: ' + error?.error?.Message, type: 'error' });
          this.energyPerformanceCertificateForm.controls.energyLabelID.enable();
          this.loading = false;
          return of({});
        })
      ).subscribe(x => {
        if (x) {
          this.router.navigate(['energylabels', x]);
        }
      });
    }
    else {
      this.energyPerformanceCertificateForm.controls.energyLabelID.enable();

      this.energyLabelService.updateEnergyLabel(this.energyPerformanceCertificateForm.value).pipe(
        catchError(error => {
          console.error(error);
          this.toastService.setMessage({ text: 'Det oppstod en feil ved sparing: ' + error?.error?.Message, type: 'error' });
          this.loading = false;
          return of({});
        })
      ).subscribe(x => {
        this.loadEnergyLabel(this.loadedEnergyLabel.energyLabelInfoID);
      });
    }
  }

  edit(): void {
    this.inReadOnlyMode = false;
    this.energyPerformanceCertificateForm.enable();
    this.energyPerformanceCertificateForm.controls.energyLabelID.disable();
  }

  cancel(): void {
    this.loading = true;

    if (this.inCreateMode) {
      this.energyPerformanceCertificateForm.reset();
      this.router.navigate(['']);
    }
    else {
      this.energyPerformanceCertificateForm.reset(this.loadedEnergyLabel);
      this.energyPerformanceCertificateForm.disable();
      this.inReadOnlyMode = true;
    }

    this.loading = false;
  }

  private mapDropdownKeyValues(dropdownItems: { id: any; name: any; }[]) {
    return dropdownItems.map(({ id, name }) => ({ key: id, value: name }));
  }
}
