import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import {map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnergyLabel } from '../models/energy-label.model';

@Injectable()
export class EnergyLabelService {
  
    constructor(private http: HttpClient) { }

    getEnergyLabel(energyLabelInfoID: string): Observable<EnergyLabel> {
        return this.http.get<{ data:EnergyLabel }>(`${environment.apiUrl}/EnergyLabel/EnergyPerformanceCertificate?energyLabelInfoID=${energyLabelInfoID}`)
            .pipe(map(result => result.data));
    }

    createEnergyLabel(energyLabel: EnergyLabel): Observable<number> {
        return this.http.post<{ data:number }>(`${environment.apiUrl}/EnergyLabel/EnergyPerformanceCertificate`, energyLabel)
            .pipe(map(result => result.data));
    }

    updateEnergyLabel(energyLabel: EnergyLabel): Observable<void> {
        return this.http.put<void>(`${environment.apiUrl}/EnergyLabel/EnergyPerformanceCertificate`, energyLabel);
    }
}